/*Hex bcb9b6 (darker gray)
Hex ebeae9 (light gray)
Hex 3c393c for text*/

body {
  font-family: 'Proxima Nova Condensed', sans-serif;
  font-size: 16px;
  color: #2d2926;
  /*background: #160f1b;*/
}

@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Hanken+Grotesk:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&family=Montserrat:ital,wght@0,100;0,400;0,600;0,700;0,800;0,900;1,100;1,400;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.cdnfonts.com/css/hvdtrial-brandon-grotesque');
@import url(https://db.onlinewebfonts.com/c/b785851e8b09ba75618fcc06d8bcae6b?family=Unica77+LL);
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

input {
  color: #353640;
  font-family: Manrope, sans-serif;
}

button {
  font-family: Manrope, sans-serif;
}

input::placeholder {
  color: #2d2926;
  font-family: Manrope, sans-serif;
  font-weight: 300;
  /*color: #9957fd;*/
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

.website-view {
  width: 100vw;
  height: 100vh;
  position: relative;
}

/* Full-screen styling */

.chat-interface-full-screen {
  position: absolute;
  bottom: 15px;
  right: 15px;
  height: 97%;
  width: 98%;
  border-radius: 8px;
  overflow: hidden;
  border-radius: 21.801px;
  border: 2.18px solid #FFF;
  background: radial-gradient(107.32% 141.42% at 0% 0%, rgba(221, 221, 221, 0.40) 0%, rgba(96, 96, 96, 0.75) 100%);
  box-shadow: -5.45016px -5.45016px 272.50803px 0px rgba(255, 255, 255, 0.02) inset;
  backdrop-filter: blur(22.890674591064453px);
}

.full-screen-container {
  padding: 20px;
  height: fit-content;
  display: flex;
  z-index: 10;
  overflow-y: hidden;
  height: 95%;
  align-items: flex-end;

}

.yurman-banner {
  height: 40px;
  width: 100%;
  background-color: #f9f9f9;
}

.yurman-banner-text {
  font-size: .75rem;
  font-style: normal;
  font-weight: 400;
  letter-spacing: .0075rem;
  line-height: 1.05rem;
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.yurman-banner-learn-more {
  text-decoration: underline;
  margin-left: 10px;
}

.yurman-navbar {
  height: 77.5px;
  width: 100%;
  padding-top: 15px;
}

.yurman-navbar-logo {
  position: absolute;
  display: block;
  height: 22px;
  width: 236px;
  left: 50%;
  transform: translate(-50%, 0%);
}

.yurman-navbar-left {
  width: 144px;
  height: 20px;
  position: absolute;
  top: 96px;
  left: 24px;
}

.yurman-navbar-right {
  width: 202.4px;
  height: 22px;
  position: absolute;
  top: 94px;
  right: 24px;
}

.yurman-navbar-link-container {
  position: absolute;
  left: 50%;
  top: 97px;
  transform: translate(-50%, 0%);
}

.yurman-navbar-link {
  font-size: .94rem;
  margin: 0;
  padding: .5em .7em calc(1em + .25vw);
  position: relative;
  text-align: center;
  display: inline;
}

.chat-section,
.product-details-section {
  flex: 1;
  overflow-y: hidden;
  position: relative;
  height: 98%;
}

.chat-send-form-fullscreen {
  position: absolute;
  bottom: 0px;
  height: 50px;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 100%;
  max-width: 98%;
}

.chat-messages-fullscreen {
  /* padding: 10px; */
  font-family: Montserrat, sans-serif;
  position: absolute;
  top: 0px;
  overflow-y: scroll;
  left: 50%;
  transform: translate(-50%, 0%);
  text-align: left;
  width: 100%;
  max-width: 97%;
  height: calc(100% - 50px);
  border-bottom-right-radius: 13.08px;
  border-bottom-left-radius: 13.08px;
}

.chat-messages-fullscreen::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.chat-messages-fullscreen {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.jenn-modal-fullscreen{
    padding: 20px;
    margin: 10px;
    z-index: 1000;
    border-radius: 13.08px;
    border: 1.09px solid #FFF;
    opacity: 0.9;
    background: #FFF;
    -webkit-backdrop-filter: blur(2.1800642013549805px);
    backdrop-filter: blur(2.1800642013549805px);
    overflow-y: scroll;
    height: 91%;
}

.close-button-fullscreen {
  position: absolute;
  right: 3%;
  top: 20px;
  border: none;
  color: white;
  cursor: pointer;
  width: 20px;
  z-index: 10000;
}

/* cart quantity */

.cart-quantity-number-wrapper {
  position: relative;
}

.cart-quantity-number-wrapper svg {
  position: absolute;
  top: 0;
  top: 5px;
  right: 77px;
  user-select: none;
  cursor: pointer;
}

.cart-quantity-number {
  color: white;
  position: absolute;
  top: 8px;
  right: 85px;
  user-select: none;
  cursor: pointer;
}

/* regular styling */

.chat-initial {
  position: absolute;
  bottom: -75px;
  right: 15px;
  width: 395px;
  height: 326px;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  /*background: radial-gradient(107.32% 141.42% at 0% 0%, rgba(221, 221, 221, 0.40) 0%, rgba(96, 96, 96, 0.75) 100%);*/
  background: transparent;
  border-radius: 25px;
  border: none;
  /*border: 2.18px solid #FFF;*/
  padding-right: 16px;
}

.chat-initial:hover {
  cursor: pointer;
}

.bubble-style-wrapped {
  display: flex;
  padding-left: 30px;
}

.bubble-style-wrapped svg {
  padding-top: 10px;
  padding-right: 5px;
}

.preview-input-wrapper {
  display: flex;
  position: relative;
  align-items: center;
}

.preview-input {
  color: #72777A;
  word-wrap: break-word;
  border: none;
  border-radius: 5px;
  width: 275px;
  border-radius: 48px;
  padding-left: 12px;
  height: 40px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.preview-input::placeholder {
  font-size: 16px;
}

.mic-svg {
  position: absolute;
  right: 67px;
  top: 15px;
}

.send-svg {
  padding: 5px;
  padding-left: 10px;
  color: rgb(236, 192, 197);
}

.bubble-style {
  background-color: #fbf6f3;
  border-radius: 24px;
  padding: 16px;
  margin: 10px 0;
  width: 80%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.bubble-style p {
  padding: 0;
  margin: 0;
}

.bubble-suggestions-style {
  background-color: rgb(236, 172, 180);
  color: rgba(255,255,255);
  border-radius: 24px;
  padding: 16px;
  margin: 5px;
  width: fit-content;
  font-weight: 600;
  max-width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.bubble-suggestions-style p {
  padding: 0;
  margin: 0;
}

/* CSS iMessage Typing Indicator*/

.typing-indicator {
  background-color: #f7f7f7;
  will-change: transform;
  width: 45px;
  border-radius: 22px;
  padding-top: 10px;
  padding-bottom: 22px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 10px;
}

.typing-indicator span {
  height: 12px;
  width: 12px;
  float: left;
  margin: 0 1px;
  background-color: #9E9EA1;
  display: block;
  border-radius: 50%;
  opacity: 0.4;
}

.typing-indicator span:nth-of-type(1) {
  -webkit-animation: 1s blink infinite 0.3333s;
  animation: 1s blink infinite 0.3333s;
}

.typing-indicator span:nth-of-type(2) {
  -webkit-animation: 1s blink infinite 0.6666s;
  animation: 1s blink infinite 0.6666s;
}

.typing-indicator span:nth-of-type(3) {
  -webkit-animation: 1s blink infinite 0.9999s;
  animation: 1s blink infinite 0.9999s;
}

@-webkit-keyframes blink {
  50% {
    opacity: 1;
  }
}

@keyframes blink {
  50% {
    opacity: 1;
  }
}

.chat-interface-wrapper {
  position: absolute;
  bottom: 0px;
  right: 0px;
  height: 100%;
  width: 450px;
  overflow: hidden;
  /*border: 2.18px solid #FFF;*/
  /*background: radial-gradient(107.32% 141.42% at 0% 0%, rgba(221, 221, 221, 0.40) 0%, rgba(96, 96, 96, 0.75) 100%);*/
  background: white;
  box-shadow: rgba(22, 22, 22, 0.1) 4px 4px 16px 0px;
  backdrop-filter: blur(22.890674591064453px);
  border: 1px solid #DEDEDE;
  /*border: solid 2px rbga;*/
}

.chat-interface {
  text-align: center;
  transition: visibility 0s, opacity 0.3s linear;
}

.chat-interface-hidden {
    visibility: hidden;
    opacity: 0;
}

.chat-interface-visible {
    visibility: visible;
    opacity: 1;
}

.chat-interface-header {
  height: 60px;
  /*background: linear-gradient(180deg, #949494 0%, rgba(183, 183, 183, 0.00) 100%);*/
  /*background: linear-gradient(180deg, #2d2926 0%, rgba(183, 183, 183, 0.00) 100%);*/
  background: rgb(236, 192, 197);
  width: 100%;
  position: absolute;
  z-index: 1000;
  border-bottom: 1px solid #DEDEDE;
}

.jenn-modal {
  position: fixed;
  top: 75px;
  left: 50%;
  transform: translate(-50%, 0%);
  background: white;
  padding: 20px;
  z-index: 1000;
  height: calc(100% - 138px);
  width: calc(90% - 40px);
  border-radius: 13.08px;
  border: 2px solid #F5F6FA;
  opacity: 0.9;
  background: #FFF;
  backdrop-filter: blur(2.1800642013549805px);
  overflow-y: scroll;
}

.jenn-modal::-webkit-scrollbar {
  width: 8px;
}

.jenn-modal::-webkit-scrollbar-thumb {
  background-color: #acadae;
  border-radius: 8px;
}

.jenn-modal::-webkit-scrollbar-track-piece{
  margin: 8px 0;
}

.expanded-product-quantity-selector-wrapper{
  position: fixed;
  bottom: 20px;
  height: 90px;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: linear-gradient(to bottom, transparent, #f6f6f6 45%);
  z-index: 10000;
  border-radius: 13.08px;
}

.expanded-product-quantity-selector {
  user-select: none;
  margin-right: 30px;
  margin-top: 20px;
  display: flex;
}

.expanded-product-quantity-value {
  display: inline-block;
}
.expanded-product-quantity-down-arrow:disabled {
opacity: 0.5;
cursor: not-allowed;
}

.expanded-product-quantity-selector-value {
padding-top: 4px;
}

.expanded-product-quantity-down-arrow {
  border: solid 1px #DEDEDE;
  color: #8F959E;
  border-radius: 100px;
  height: 10px;
  width: 10px;
  padding: 7px;
  margin-right: 10px;
  cursor: pointer;
}

.expanded-product-quantity-up-arrow {
  border: solid 1px #DEDEDE;
  color: #8F959E;
  border-radius: 100px;
  height: 10px;
  width: 10px;
  padding: 7px;
  margin-left: 10px;
  cursor: pointer;
}

.expanded-product-wrapper{
  padding-bottom: 40px;
}

.expanded-product-image-carousel {
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  height: 300px;
  width: 100%;
  background: white;
  margin-bottom: 25px;
}

.expanded-product-image-carousel-image-container {
  transition: transform 0.5s ease;
  display: flex;
}

.expanded-product-image-carousel-image-container img {
  height: 200px;
  width: 200px;
  object-fit: cover;
  /*overflow: hidden;*/
  display: block;
  scroll-snap-align: start;
  transition: transform 0.5s ease, opacity 0.5s ease, margin-top 0.5s ease, padding-left 0.5s ease, padding-right 0.5s ease;
  opacity: 0.3;
  border-radius: 10px;
}

.expanded-product-image-carousel-image-container img.active {
  transform: scale(1.5);
  margin-top: 50px;
  margin-left: 50px;
  margin-right: 50px;
  opacity: 1;
  border-radius: 10px;
}

.expanded-product-image-carousel-left-arrow,
.expanded-product-image-carousel-right-arrow {
  position: absolute;
  top: 100px;
  transform: translateY(-50%);
  height: 75px;
  padding: none;
  color: white;
  border: none;
  cursor: pointer;
  z-index: 100;
}

.expanded-product-image-carousel-left-arrow {
  left: 10px;
}

.expanded-product-image-carousel-right-arrow {
  right: 10px;
}

.expanded-product-title-label-row {
  display: block;
  margin-bottom: 42px;
  font-weight: 400;
  font-size: 12px;
  color: #8F959E;
}

.expanded-product-title-label {
  display: inline-block;
  left: 20px;
  position: absolute;
}

.expanded-product-price-label {
  display: inline-block;
  right: 20px;
  position: absolute;
}

.expanded-product-title-row {
  display: block;
  font-size: 20px;
  color: #1D1E20;
  font-weight: 600;
  margin-bottom: 80px;
}

.expanded-product-title {
  display: inline-block;
  left: 20px;
  position: absolute;
}

.expanded-product-price {
  display: inline-block;
  right: 20px;
  position: absolute;
}

.expanded-product-description-label {
  color: #1D1E20;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%;
  /* 17.6px */
  text-align: left;
  margin-bottom: 8px;
}

.expanded-product-description {
  color: #8F959E;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 16.8px */
  text-align: left;
}

.expanded-product-review-label-wrapper{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 8px;
  padding-right: 30px;
}

.expanded-product-review-label {
  color: #1D1E20;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%;
  text-align: left;
}

.expanded-product-review-view-all{
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  outline: inherit;
  color: #000;
  font-size: 12px;
  font-family: Inter;
  font-weight: 600;
  line-height: 13.20px;
  word-wrap: break-word
}

.expanded-product-review {
  color: #8F959E;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 16.8px */
  text-align: left;
}

.review-item {
  display: flex;
  margin-bottom: 20px;
}

.review-photo-container {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 15px; /* Adjust the spacing between the photo and other content */
  display: flex;
  flex-direction: column;
}

.review-content {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 30px;
}

.review-photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.review-name{
  color: #1D1E20;
  font-size: 15px;
  font-family: Inter;
  font-weight: 500;
  line-height: 16.50px;
  word-wrap: break-word
}

.review-date-wrapper{
  display: flex;
  align-items: center;
  margin-top: 4px;
}

.review-date-wrapper svg{
  padding-right: 5px;
}

.review-date{
  color: #8F959E;
 font-size: 11px;
 font-family: Inter;
 font-weight: 500;
 line-height: 11px;
 word-wrap: break-word
}
.review-rating-number{
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.review-rating-number span{
  color: #1D1E20;
font-size: 15px;
font-family: Inter;
font-weight: 500;
line-height: 16.50px;
word-wrap: break-word
}

.review-rating-number p {
  color: #8F959E;
  font-size: 11px;
  font-family: Inter;
  font-weight: 400;
  line-height: 12.10px;
  word-wrap: break-word
}

.login-page-container {
  background: #160f1b;
}

.login-page {
  text-align: center;
}

.login-page-message {
  font-family: 'Manrope', sans-serif;
  color: white;
  margin: 0 auto;
  position: absolute;
  font-size: 36px;
  font-weight: 700;
  top: 120px;
  left: 50%;
  transform: translate(-50%, 0%);
}

.chat-send-form {
  position: absolute;
  bottom: 0px;
  height: 65px;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 90%;
  max-width: 90%;
}

.chat-messages {
  padding-top: 60px;
  font-family: 'Proxima Nova Condensed', sans-serif;
  position: absolute;
  /*top: 154.05px;*/
  top: 0px;
  overflow-y: scroll;
  left: 50%;
  transform: translate(-50%, 0%);
  text-align: left;
  width: 90%;
  max-width: 90%;
  height: calc(100% - 140px);
  border-bottom-right-radius: 13.08px;
  border-bottom-left-radius: 13.08px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.chat-messages::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.chat-messages {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.chat-message-ai {
  text-align: left;
  margin-top: 5px;
  margin-bottom: 5px;
}

.chat-quiz-option-container {
  display: flex;
  flex-direction: column;
}

.chat-quiz-option-container .chat-quiz-option:first-child {
  margin-bottom: 5px;
  margin-top: 0;
}

.chat-quiz-option {
  font-size: 14px;
  font-weight: 400;
  appearance: none;
  border-radius: 14px; 
  border: 1px rgba(255, 255, 255, 0.50) solid;
  max-width: 33%;
  outline: none;
  padding: 12px 15px; 
  margin: 5px 0; 
  background-color: rgb(244, 245, 247);
  cursor: pointer;
  transition: background-color 0.3s;
  color: rgba(95, 128, 248, 1);
}

.chat-quiz-option.selected {
  background-color: #000;
  color: rgba(255, 255, 255, 1);
}

.chat-quiz-option-submit {
  width: 50%;
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-weight: 400;
  appearance: none;
  border-radius: 14px; 
  border: 1px rgba(255, 255, 255, 0.50) solid;
  max-width: 50%;
  outline: none;
  padding: 12px 15px; 
  margin: 5px 0; 
  background-color: #000;
  cursor: pointer;
  color: rgba(255, 255, 255, 1);
}

.chat-message-user {
  text-align: right;
  margin-top: 5px;
  margin-bottom: 5px;
}

.chat-message-text-ai {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 300;
  /*background: #fff7f2;*/
  /*border: solid 1px #ebebeb;*/
  color: #353640;
  display: inline-block;
  overflow-wrap: break-word;
  max-width: 85%;
  line-height: 1.5;
  border-radius: 4px;
  border-top-left-radius: 0px;
  /*border-top-right-radius: 13.08px;*/
  /*border-top-right-radius: 13.08px;*/
  /*border-top-right-radius: 13.08px;*/
  letter-spacing: 0.01em;
  /*background: linear-gradient(103deg, #FFF 0%, rgba(255, 255, 255, 0.85) 100%);*/


}

.chat-message-ai-container{
  /* background: rgb(244, 245, 247); */
  background: #fbf6f3;
  padding: 12px 15px;
  max-width: 85%;
  line-height: 1.5;
  border-radius: 13.08px;
  border-top-left-radius: 0px;
  box-shadow: 0px 2.897px 7.244px 0px rgba(0, 0, 0, 0.10);
  backdrop-filter: blur(21.73077392578125px); 
}

.chat-message-text-user {
  padding: 12px 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  /*background: rgb(0, 120, 254);*/
  /*color: #2d2926;*/
  color: white;
  display: inline-block;
  overflow-wrap: break-word;
  max-width: 85%;
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  border-radius: 4px;
  border-bottom-right-radius: 0px;
  background: rgb(236, 192, 197);
  letter-spacing: 0.01em;
}

.input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.chat-input {
  width: calc(100% - 34px);
  /* color: #ecc0c5; */
  color: #252525;
  padding: 12px 15px;
  padding-right: 46px;
  font-family: Manrope, sans-serif;
  font-size: 14px;
  border-radius: 4px;
  border: 1.23px solid #ecc0c5;
}

.chat-input-hybrid {
  width: calc(100% - 34px);
  color: #ecc0c5;
  padding: 12px 15px;
  padding-right: 46px;
  padding-left: 46px;
  font-family: Manrope, sans-serif;
  font-size: 14px;
  border-radius: 4px;
  border: 1.23px solid #ecc0c5;
}

.chat-input::placeholder {
    color: #ecc0c5;
}

.chat-input-hybrid::placeholder {
    color: #ecc0c5;
}

.speech-transcriber {
  position: absolute;
  left: 50%;
  top: 4px;
  transform: translate(-50%, 0%);
  height: 36px;
  width: 36px;
  border-radius: 76.562px;
  box-shadow: 3.2701px 3.2701px 13.08039px 0px rgba(0, 0, 0, 0.05);
  border: none;
  z-index: 10;
}

.speech-transcriber-hybrid {
  position: absolute;
  left: 5px;
  top: 4px;
  height: 36px;
  width: 36px;
  border-radius: 76.562px;
  box-shadow: 3.2701px 3.2701px 13.08039px 0px rgba(0, 0, 0, 0.05);
  border: none;
  z-index: 10;
}

.speech-transcriber-button {
  position: absolute;
  left: 0px;
  top: 0px;
  height: 36px;
  width: 36px;
  border-radius: 76.562px;
  background-color: rgb(236, 192, 197);
  border: none;
  z-index: 11;
}

.speech-transcriber-button.recording {
  background-color: darkgrey;
}

.speech-transcriber-mic-icon {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 6px;
  left: 6px;
}

.input-svg-button {
  position: absolute;
  right: 5px;
  /* Adjust as needed */
  height: 36px;
  /* Adjust as needed */
  width: 36px;
  /* Adjust as needed */
  border-radius: 76.562px;
  background: #ecc0c5;
  box-shadow: 3.2701px 3.2701px 13.08039px 0px rgba(0, 0, 0, 0.05);
  border: none;
}

.input-svg {
  position: absolute;
  height: 30px;
  /* Adjust as needed */
  width: 30px;
  /* Adjust as needed */
  bottom: 3px;
  right: 3px;
}

.logo {
  height: 40px;
  position: absolute;
  left: 10px;
  top: 10px;
  cursor: pointer;
}

.welcome-message {
  display: table;
  font-family: Manrope, sans-serif;
  font-weight: 400;
  font-size: 16px;
  margin-top: 54.05px;
  height: 100px;
  vertical-align: middle;
  width: 100%;
  text-align: center;
}

.welcome-message-text-container {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  width: 100%;
  max-width: 90%;
}

.welcome-message-text {
  text-align: center;
  margin: 0 auto;
}

.login-username {
  border-radius: 12px;
  font-family: Manrope, sans-serif;
  font-size: 16px;
  border: solid #adb5bd 2px;
  /*border: solid #9957fd 2px;*/
  /*border: none;*/
  padding: 12px 15px;
  display: block;
  width: 200px;
}

.login-password {
  border-radius: 12px;
  font-family: Manrope, sans-serif;
  font-size: 16px;
  border: solid #adb5bd 2px;
  /*border: solid #9957fd 2px;*/
  /*border: none;*/
  padding: 12px 15px;
  display: block;
  width: 200px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.login-page {
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  text-align: center;
}

.login-submit {
  background: #adb5bd;
  /*background: #2f34d3;*/
  /*background: #9957fd;*/
  font-family: Manrope, sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: white;
  border: none;
  border-radius: 8px;
  /*padding: 12px 15px;*/
  padding: 1rem 1.5rem;
  width: 110px;
  margin-right: 5px;
}

.signup-submit {
  background: #adb5bd;
  /*background: #2f34d3;*/
  /*background: #9957fd;*/
  font-family: Manrope, sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: white;
  border: none;
  border-radius: 8px;
  /*padding: 12px 15px;*/
  padding: 1rem 1.5rem;
  margin-left: 5px;
  width: 110px;
}

.loading-indicator {
  background-color: #e6e7ed;
  will-change: transform;
  width: auto;
  border-radius: 50px;
  padding: 15px;
  display: table;
  margin: 9px 0 20px 14px;
  position: relative;
  animation: 2s bulge infinite ease-out;
}

.loading-indicator::before,
.loading-indicator::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: -2px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #e6e7ed;
}

.loading-indicator::after {
  height: 10px;
  width: 10px;
  left: -10px;
  bottom: -10px;
}

.loading-indicator span {
  height: 11px;
  width: 11px;
  float: left;
  margin: 0 1px;
  background-color: #9e9ea1;
  display: block;
  border-radius: 50%;
  opacity: 0.4;
}

.loading-indicator span:nth-of-type(1) {
  animation: 1s blink infinite 0.3333s;
}

.loading-indicator span:nth-of-type(2) {
  animation: 1s blink infinite 0.6666s;
}

.loading-indicator span:nth-of-type(3) {
  animation: 1s blink infinite 1s;
}

@keyframes blink {
  50% {
    opacity: 1;
  }
}

@keyframes bulge {
  50% {
    transform: scale(1.05);
  }
}


#login-toast {
  margin-top: 10px;
  font-family: Manrope, sans-serif;
  font-size: 16px;
  background: #faacbc;
  padding: 16px 30px;
  border-radius: 8px;
}

#login-toast-text {
  overflow-wrap: break-word;
  width: 140px;
  text-align: left;
}

.navigation {
  width: 250px;
  height: 100%;
  background-color: #fff7f2;
  color: white;
  font-family: Manrope, sans-serif;
  position: absolute;
  transform: translateX(-100%);
  transition: transform 0.25s ease-out;
  z-index: 10;
  top: 0px;
  overflow: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.navigation::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.navigation {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.overlay {
  background: rgba(70, 70, 70, .7);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  z-index: 0;
  opacity: 0;
  /* Start with 0 opacity */
  visibility: hidden;
  transition: visibility 0s 0.25s, opacity 0.25s linear;
  z-index: 5;
}

.overlay.active {
  transform: translateX(0);
  opacity: 1;
  visibility: visible;
  transition: visibility 0s 0s, opacity 0.25s linear;
}

.navigation.active {
  transform: translateX(0);
}

.navigation-items {
  margin-top: 0;
}

.nav-item {
  padding: 10px 20px;
  color: rgb(40, 40, 50);
  margin: 5px 0;
  cursor: pointer;
  /*border-bottom: solid 1px white;*/
}

.nav-item-divider {
  height: 1px;
  width: 90%;
  background: rgb(230, 230, 230);
  margin: 0 auto;
}

.nav-item-text {
  display: inline-block;
  cursor: pointer;
}

.nav-button {
  position: absolute;
  right: 10px;
  top: 10px;
  border: none;
  color: rgb(40, 40, 50);
  border-radius: 8px;
  /*height: 40px;*/
  /*width: 40px;*/
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 12px;
  padding-right: 12px;
  font-family: Manrope, sans-serif;
  color: white;
  background: #e2291b;
  font-weight: 600;
  cursor: pointer;
}

.close-button {
  position: absolute;
  right: 5%;
  top: 20px;
  border: none;
  cursor: pointer;
  width: 20px;
  color: white;
}

.cart-button {
  position: absolute;
  right: calc(10% + 10px);
  top: 20px;
  border: none;
  color: white;
  cursor: pointer;
  width: 20px;
}

.cart-button-modal-open {
  position: absolute;
  right: 5%;
  top: 20px;
  border: none;
  color: white;
  cursor: pointer;
  width: 20px;
}

.expand-button {
  position: absolute;
  left: 5%;
  top: 20px;
  border: none;
  color: white;
  cursor: pointer;
  width: 20px;
}

.modal-close-button {
  position: absolute;
  left: 5%;
  top: 20px;
  border: none;
  color: #000;
  cursor: pointer;
  width: 30px;
}

.cart-product-card {
  margin-bottom: 10px;
}

.cart-product-card:last-child {
  margin-bottom: none;
}

.cart-product-card-quantity-down-arrow {
  border: solid 1px #DEDEDE;
  color: #8F959E;
  border-radius: 100px;
  height: 10px;
  width: 10px;
  padding: 7px;
  margin-right: 10px;
  cursor: pointer;
}

.cart-product-card-quantity-up-arrow {
  border: solid 1px #DEDEDE;
  color: #8F959E;
  border-radius: 100px;
  height: 10px;
  width: 10px;
  padding: 7px;
  margin-left: 10px;
  cursor: pointer;
}

.cart-product-card-trash {
  border: solid 1px #DEDEDE;
  color: #8F959E;
  border-radius: 100px;
  height: 12px;
  width: 13px;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 5.5px;
  padding-right: 5.5px;
  cursor: pointer;
}

.cart-product-card-quantity-value {
  display: inline-block;
}

.cart-product-card {
  display: flex;
  justify-content: space-between;
  /* This will space out the main children (image, details, price) */
  align-items: center;
  padding: 10px;
  width: calc(100% - 20px);
  /* Adjust width as necessary */
  border-radius: 10px;
  background: #FEFEFE;
  box-shadow: 0px 40px 100px -15px rgba(57, 63, 74, 0.25);
}

.cart-product-title {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}

.cart-product-card-image img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  overflow: hidden;
  background-color: #eee;
  /* Placeholder color */
  /*margin-right: 10px;*/
  border-radius: 8px;
}

.cart-product-card-image {
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 10px;
  padding-bottom: 6px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.10);
  border-radius: 10px;

}

.cart-product-card-details-left {
  text-align: left;
  height: 150px;
  width: 70%;
  padding-left: 20px;
  padding-right: 20px;
}

.cart-product-card-details-right {
  text-align: left;
  height: 150px;
  width: 20%;
  padding-left: 10%;
  padding-right: 20px;
}

.cart-product-card-price-label {
  font-size: 12px;
  color: #8F959E;
}

.cart-product-card-price {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 15px;
}

.cart-product-card-quantity-down-arrow:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.cart-product-card-quantity-selector {
  user-select: none;
  margin-top: 15px;
  display: flex;
}

.cart-product-card-quantity-selector-value {
  padding-top: 4px;
}

.cart-end-checkout-container{
  margin-top: 15px;
  text-align: left;
}

.cart-end-checkout-container strong{
  font-weight: 600;
}

.cart-end-checkout-container hr{
  border-top: rgba(0, 0, 0, 0.1) .5px solid;
}


.cart-end-checkout-total-price-and-shipping-info-container{
  margin-top: 5px;
  font-size: 13px;
  display: flex;
  flex-wrap: wrap;
}

.cart-end-checkout-klarna-text{
  font-size: 13px;
  display: block;
}

.cart-end-checkout-klarna-text a:link{
  color: #000;
}

.cart-end-checkout-total-price-and-shipping-info-shipping{
  /* border: 1.09px solid red; */
  width: 50%;
  line-height: 150%;
  margin-top: 12px;
}
.cart-end-checkout-total-price-and-shipping-info-total{
  margin-left: auto;
  text-align: right;
  font-weight: 400;
  line-height: 10px;
  margin-top: 5px;
}

.cart-end-checkout-total-price-and-shipping-info-total h1{
  line-height: 0px;
  font-size: 30px;
  font-family: Inter;
}

.cart-end-checkout-customer-input-container{
  margin-top: 18px;
  font-size: 13px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.cart-end-checkout-customer-input-helper-text{
  margin-bottom: 12px;
}

.cart-end-checkout-input-full-width-container{
  font-weight: 600;
  font-size: 15px;
  margin-top: 10.5px;
  width: 100%;
}

.cart-end-checkout-input-half-width-container{
  font-weight: 600;
  font-size: 15px;
  margin-top: 10.5px;
  width: 48%;
}

.cart-end-checkout-input-field{
  border-radius: 10px;
  background: #F5F6FA;
  border: none;
  padding: 10px 10px 10px 15px;
  margin-top: 8.5px;
  width: 100%;
  box-sizing: border-box;
}

.cart-end-checkout-action-back-button{
  margin-top: 15px;
  border-radius: 100px;
  border: 1px solid #282828;
  padding: 10px;
  width: 45%;
  text-align: center;
  font-weight: 600;
  cursor: pointer;
}

.cart-end-checkout-action-continue-button{
  margin-top: 15px;
  border-radius: 100px;
  background: #2d2926;
  color: #FFF;
  padding: 10px;
  width: 45%;
  text-align: center;
  font-weight: 600;
  cursor: pointer;
}

@media (max-width: 768px) {
  .cart-product-card-product-card {
    width: auto;
  }
}


.logo-nav {
  height: 40px;
  position: absolute;
  top: 10px;
  left: 24%;
  cursor: pointer;
  z-index: 11;
}

.logo-nav-container {
  height: 120px;
  width: 250px;
  margin-bottom: 5px;
  position: sticky;
  top: 0px;
  left: 0px;
  cursor: pointer;
  z-index: 11;
  background: #fff7f2;
}

.new-chat-container {
  background: #F9F8FA;
  position: absolute;
  height: 60px;
  top: 60px;
  left: 0px;
}

.new-chat-button {
  position: absolute;
  /*height: 40px;*/
  padding-top: 12px;
  padding-bottom: 12px;
  padding: 10px;
  top: 5px;
  width: 190px;
  left: 20px;
  border-radius: 8px;
  background: #e2291b;
  text-align: center;
  font-size: large;
  letter-spacing: 0.5px;
  font-weight: 600;
}

.product-highlight {
  display: inline;
  color: rgb(236, 192, 197);
  font-weight: 800;
  cursor: pointer;
}

.chat-message-ai-text-container {
  display: inline;
}

.product-cards-container {
  width: 430px;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  padding-top: 20px;
  align-items: flex-start;
  /* Aligns items to the top */
  height: auto;
  padding-bottom: 8px;
  gap:20px
}

.product-cards-container::-webkit-scrollbar {
  height: 7px;
}

.product-cards-container::-webkit-scrollbar-thumb {
  background-color: #5c5c5c; 
  border-radius: 6px;
}

.product-cards-container::-webkit-scrollbar-track {
  background-color: transparent;
}

.checkout-button{ 
  width: 170px; 
  height: 40px;
  border-radius: 100px;
  background-color: #131313;
  color: whitesmoke;
  border: 1px rgba(167.83, 186.63, 255, 0.30) solid;
  cursor: pointer;
  margin-top: 10px;
  font-weight: 600;
  font-size: 16px;
}

/*box-shadow: 0px 40px 100px -15px rgba(57, 63, 74, 0.25);
box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.10);*/

.product-card-name {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 14px;
  color: black;
  /*font-weight: 600;*/
  font-weight: 400;
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5em;
  /* Adjust based on your font size */
  height: 3em;
  /* 2 lines of text, adjust as needed */
  /*text-transform: uppercase;*/
  letter-spacing: 0.05em;
}

.product-card-price {
  /*font-weight: 700;*/
  margin-top: 5px;
  margin-bottom: 10px;
  font-size: 13px;
  color: #000;
  font-weight: 700;
  text-align: left;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  overflow: hidden
}

.product-card-image {
  height: 120px;
  max-width: 100%;
  border-radius: 8px;
  object-fit: cover;
}

.product-card-add-to-cart {
  color: white;
  border-radius: 10px;
  border: 1px solid #A8BBFF;
  background: #000;
  text-align: center;
  vertical-align: middle;
  height: 26px;
  font-size: 12px;
  padding-top: 7px;
  font-weight: 600;
}

.product-card-first-row {
  display: flex;
  width:100%;
  flex-direction: column;
  align-items: start;
  text-align: left;
  justify-content: space-between;
  height: 100%;
}
.product-card-bottom {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.product-card-learn-more {
  border-top: solid 1px black;
  height: 50px;
  /*color: #e2291b;*/
}

.product-card-learn-more-image {
  margin-top: 14px;
  margin-right: 10px;
  width: 20px;
  float: left;
  color: #e2291b;
}

.product-card-learn-more-text {
  display: inline;
  margin-top: 9px;
  float: left;
}

.product-card-add-to-cart-image {
  margin-top: 17px;
  margin-right: 10px;
  width: 20px;
  float: left;
}

.product-card-add-to-cart-text {
  display: inline;
  margin-top: 9px;
  float: left;
}

.product-card-description {
  display: block;
  /* Define the height of the box based on line-height and the number of lines you want to show (e.g., 5 lines) */
  /* Assuming a line-height of 1.2em, the following would calculate as 1.2em * 5 = 6em */
  max-height: 6em;

  /* Required for the overflow properties to take effect */
  overflow: hidden;

  /* This creates the ellipsis effect for overflow text */
  display: -webkit-box;
  -webkit-line-clamp: 5;
  /* number of lines to show */
  -webkit-box-orient: vertical;

  /* Ensure the content is aligned as you wish */
  text-align: left;
}

.product-card {
  width: 198px;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  padding: 17px;
}

.product-title, .product-description {
  white-space: nowrap;       /* Ensures no line breaks within the text */
  overflow: hidden;          /* Hide text that goes beyond the element's box */
  text-overflow: ellipsis;   /* Add ellipsis at the end of the truncated text */
  max-width: 100%;           /* Optional: you might adjust this depending on your design */
}

.product-title {
  font-weight: bold;
  text-align: left;
  font-size: 16px;
  margin-bottom: 5px;
  margin-block-start: 0.3em;
  margin-block-end: 0em;
}

.product-description {
  color: #333;
  margin-bottom: 15px;
  margin-block-start: 0em;
  margin-block-end: 0.8em;
  font-size: 14px;
}


.size-button.out-of-stock {
  color: #ccc;
  cursor: not-allowed;
}

.color-options {
  display: flex;
  justify-content: left;
  margin-bottom: 15px;
  overflow-x: auto;
  margin-left: -5px;
  width:100%;
  /* flex-wrap: wrap; */
}

.color-option {
  width: 30px;
  height: 30px;
  border: 1px solid #eaeaea;
  margin: 0 5px;
  cursor: pointer;
  flex: 0 0 auto;
  object-fit: cover; 
  margin-bottom: 10px;
}

.size-options {
  display: flex;
  gap: 5px;
  margin-bottom: 20px;
  overflow-x: auto;
  width: 100%;
}

.size-button {
  border: 0.1px solid #000;
  background-color: transparent;
  padding: 5px 10px;
  cursor: pointer;
  width: auto;
  height: 18px;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-wrap: nowrap;
  color: #000;
}

.size-button:hover {
  background-color: #f0f0f0;
}

.add-to-bag-button {
  flex-grow: 1;
  background-color: rgb(236, 192, 197);
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
  font-size: 9px;
  font-weight: 700;
}

.add-to-bag-button:hover {
  background-color: #333;
}

.product-price {
  font-size: 14px;
  color: #333;
  white-space: nowrap;
  margin-left:17px;
  text-align: center;

}

.image-container {
  width: 198px;
  height: 124px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  background-color: white;
}

.product-image {
  height: 100%;
  width:auto;
  object-fit: cover;
}

.product-buttons-container{
  display: flex;
  width: 100%;
  height: 24px;
  align-items: center;
}


.options-container{
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap:5px
}
.options-container-row{
  display: flex;
  width: 90%;
  gap: 5px;
}
.options-container-button{
  width: 50%;
  height: 35px;
  background-color: transparent;
  border: 1px solid #000;
  cursor: pointer;
}

/* Desktop */
@media (min-width: 2000px) {
  .overlay {
    transform: translateX(0);
  }

  .overlay.active {
    display: none;
  }

  .nav-button {
    display: none;
  }

  .chat-send-form {
    margin-left: 122px;
    max-width: calc(90% - 290px);
  }

  .chat-messages {
    margin-left: 122px;
    max-width: calc(90% - 290px);
    top: 0px;
    height: calc(100% - 80px);
  }

  .welcome-message-text-container {
    margin-left: 122px;
    max-width: calc(90% - 290px);
  }

  .chat-message-text-ai {
    max-width: 85%;
  }

  .chat-message-text-user {
    max-width: 85%;
  }
}

/* Mobile styling */
@media (max-width: 800px) {
  .chat-interface-wrapper{
    bottom: 0px;
    right: 0px;
    height: 100%;
    width: 100%;
    border-radius: 0px;
  }
  .chat-initial{
    bottom: -60px;
  }
  .carousel-item-wrapper.active{
    width: 70%;
  }
  .chat-section{
    width: 100%;
  }
  .full-screen-container{
    flex-direction: column-reverse;
  }
}